<template>
  <div class="vx-col md:w-1/1 w-full mb-base">
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>

      <template slot="thead">
        <vs-th sort-key="">Action</vs-th>
        <vs-th sort-key="company_name">Company</vs-th>
        <vs-th sort-key="work_group_name">WorkGroup</vs-th>
        <vs-th sort-key="work_id_number">Work ID Number</vs-th>
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="address">Address</vs-th>
        <vs-th sort-key="mobile">Mobile</vs-th>
        <vs-th sort-key="warehouse_name">Warehouse</vs-th>
        <vs-th sort-key="code_external">Code External</vs-th>
        <vs-th sort-key="role_name">Role</vs-th>
        <vs-th sort-key="status">Status</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-edit"
                title="Edit"
                style="margin-right: 5px"
                @click="handleEdit(tr.id)"
              />
            </div>
          </vs-td>
          <vs-td>{{ tr.company_name }}</vs-td>
          <vs-td>{{ tr.work_group_name }}</vs-td>
          <vs-td>{{ tr.work_id_number }}</vs-td>
          <vs-td>{{ tr.name }}</vs-td>
          <vs-td>{{ tr.address }}</vs-td>
          <vs-td>{{ tr.mobile }}</vs-td>
          <vs-td>{{ tr.warehouse_name }}</vs-td>
          <vs-td>{{ tr.code_external }}</vs-td>
          <vs-td>{{ tr.role_name }}</vs-td>
          <vs-td>
            <div
              v-if="tr.status == 'Active'"
              class="bg-success text-white rounded-full px-3 py-1 text-sm font-semibold"
            >
              {{ tr.status }}
            </div>
            <div
              v-else
              class="bg-grey text-white rounded-full px-3 py-1 text-sm font-semibold"
            >
              {{ tr.status }}
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      table: this.tableDefaultState(),
      mutableAction: "",
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/setting/users/all", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleEdit(id) {
      this.$router.push({
        name: "setting.user.edit",
        params: { id: id },
      });
    },
  },
  watch: {
    action(val) {
      this.getData();
      this.mutableAction = val;
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
