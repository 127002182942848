<template>
  <vx-card title="Setting - User">
    <div class="tab-text">
      <div class="vx-row w-1/2 mb-6 mt-3 ml-5">
        <vs-button
          class="mt-2"
          color="primary"
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="handleCreate()"
          >Create</vs-button
        >
      </div>

      <div class="vx-row mb-12">
        <div class="vx-col sm:w-1/1 w-full flex">
          <Table></Table>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import Table from "./Table.vue";
// import Form from "./Form.vue";

export default {
  components: {
    Table,
  },
  data() {
    return {
      selectedId: 0,
    };
  },
  methods: {
    handleCreate() {
      this.$router.push({
        name: "setting.user.create",
      });
    },
  },
};
</script>
